import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import { GET, PATCH } from "@/core/services/store/request.module";
import SignaturePad from "signature_pad";

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      //quotationTab: "tab-other",
      quotation: 0,
      jobId: 0,
      invoiceId: 0,
      customerId: 0,
      convertQuotationId: 0,
      customerPersonId: 0,
      customerPropertyId: 0,
      customerPropertyDialog: false,
      salesSignatureDialog: false,
      particuleFieldLoading: false,
      signatureLoading: false,
      createdDatePicker: false,
      sentDatePicker: false,
      openTillPicker: false,
      pageLoading: true,
      deleteValid: false,
      deleteLoading: false,
      deleteDialog: false,
      deleteLineItemDialog: false,
      approval_dialog: false,
      approval_loading: false,
      lineItemDialog: false,
      sales_signature: null,
      deleteDetail: {},
      detail: {},
      customer: {},
      property: {},
      billing: {},
      contact_person: {},
      line_items: {},
      moreActions: [],
      updateDetailDialog: {
        description: false,
        job_title: false,
        rating: false,
        reference: false,
        sales: false,
        prepared_by: false,
        date: false,
        sent_date: false,
        open_till: false,
      },
      updateDetail: {
        description: null,
        job_title: null,
        rating: null,
        reference: null,
        sales: null,
        prepared_by: null,
        date: null,
        sent_date: null,
        open_till: null,
      },
    };
  },
  watch: {
    deleteLineItemDialog(param) {
      if (!param) {
        this.deleteDetail = new Object();
      }
    },
    $route(to, from) {
      if (
        (from.name === "admin.quotation.detail" ||
          from.name === "engineer.quotation.detail") &&
        (to.name === "admin.quotation.detail" ||
          to.name === "engineer.quotation.detail") &&
        this.lodash.isEqual(to.params, from.params) === false
      ) {
        this.pageLoading = true;
        this.quotation = this.lodash.toSafeInteger(to.params.id);
        this.loadContent();
      }
    },
  },
  methods: {
    approveQuotation() {
      this.approval_loading = true;
      this.patchQuotation({ is_approved: 1 }, true)
        .then(() => {
          this.loadContent();
          this.approval_dialog = false;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.approval_loading = false;
        });
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "edit":
          _this.editQuotation();
          break;
        case "convert_to_job":
          _this.convertToJob();
          break;
        case "convert_to_invoice":
          _this.convertToInvoice();
          break;
        case "convert_to_project":
          _this.convertToProject();
          break;
        case "duplicate":
          _this.duplicateQuotation();
          break;
        case "revise":
          _this.reviseQuotation();
          break;
        case "send_as_email":
          /*_this.salesSignatureDialog = true;
          _this.initSignature();*/
          _this
            .patchQuotation({ status: 5 }, true)
            .then(() => {
              _this.loadContent();
            })
            .catch((error) => {
              _this.logError(error);
            });
          break;
        case "mark_as_approved":
          _this.approval_dialog = true;
          break;
        case "mark_as_closed":
          _this
            .patchQuotation({ status: 7 }, true)
            .then(() => {
              _this.loadContent();
            })
            .catch((error) => {
              _this.logError(error);
            });
          break;
        case "mark_as_cancelled":
          _this
            .patchQuotation({ status: 6 }, true)
            .then(() => {
              _this.loadContent();
            })
            .catch((error) => {
              _this.logError(error);
            });
          break;
        case "mark_as_accepted":
          _this
            .patchQuotation({ status: 2 }, true)
            .then(() => {
              _this.loadContent();
            })
            .catch((error) => {
              _this.logError(error);
            });
          break;
        case "mark_as_rejected":
          _this
            .patchQuotation({ status: 3 }, true)
            .then(() => {
              _this.loadContent();
            })
            .catch((error) => {
              _this.logError(error);
            });
          break;
        case "mark_as_pending":
          _this
            .patchQuotation({ status: 4 }, true)
            .then(() => {
              _this.loadContent();
            })
            .catch((error) => {
              _this.logError(error);
            });
          break;
        case "download_pdf":
          window.open(
            process.env.VUE_APP_API_URL +
              "quotation/" +
              _this.detail.id +
              "/pdf/download",
            "_blank"
          );
          break;
        case "print":
          window.open(
            process.env.VUE_APP_API_URL +
              "quotation/" +
              _this.detail.id +
              "/print",
            "_blank"
          );
          break;
      }
    },
    convertToJob() {
      const _this = this;
      if (_this.getPermission("job:create")) {
        _this.convertQuotationId = _this.detail.id;
        _this.customerPropertyDialog = true;
      }
    },
    convertToInvoice() {
      const _this = this;
      if (_this.getPermission("invoice:create")) {
        _this.$router.push(
          _this.getDefaultRoute("invoice.create", {
            query: {
              quotation: _this.detail.id,
              customer: _this.lodash.toSafeInteger(
                _this.detail.customer.customer
              ),
              contact_person: _this.lodash.toSafeInteger(
                _this.detail.contact_person.contact_person
              ),
              property: _this.lodash.toSafeInteger(
                _this.detail.property.property
              ),
            },
          })
        );
      }
    },
    convertToProject() {
      const _this = this;
      if (_this.getPermission("project:create")) {
        _this.$router.push(
          _this.getDefaultRoute("project.create", {
            query: {
              quotation: _this.detail.id,
              customer: _this.lodash.toSafeInteger(
                _this.detail.customer.customer
              ),
            },
          })
        );
      }
    },
    resetAll() {
      this.customerId = 0;
      this.customerPersonId = 0;
      this.customerPropertyId = 0;
      this.customerPropertyDialog = false;
    },
    selectCustomerProperty(param) {
      this.customerPropertyId = param;
      this.checkJob();
    },
    checkJob() {
      const _this = this;
      if (
        _this.customerId > 0 &&
        _this.customerPersonId > 0 &&
        _this.customerPropertyId > 0
      ) {
        _this.$router.push(
          _this.getDefaultRoute("job.create", {
            query: {
              customer: _this.customerId,
              contact_person: _this.customerPersonId,
              property: _this.customerPropertyId,
              quotation: _this.convertQuotationId,
            },
          })
        );
      }
    },
    submitSalesSignature() {
      const _this = this;
      try {
        if (_this.sales_signature.isEmpty()) {
          return false;
        }

        _this.signatureLoading = true;

        _this
          .patchQuotation({
            status: 5,
            signature: _this.sales_signature.toDataURL(),
          })
          .then(() => {
            _this.loadContent();
            _this.salesSignatureDialog = false;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.signatureLoading = false;
          });
      } catch (error) {
        _this.logError(error);
        _this.signatureLoading = false;
      }
    },
    initSignature() {
      const _this = this;
      setTimeout(function () {
        _this.$nextTick(() => {
          let ccanvas = _this.$refs["sales_signature"];
          let cparentDiv = document.getElementById("signature-pad");
          let cparentWidth = cparentDiv.offsetWidth;
          ccanvas.setAttribute("width", cparentWidth);
          _this.sales_signature = new SignaturePad(ccanvas);
        });
      }, 500);
    },
    duplicateQuotation() {
      const _this = this;
      if (_this.getPermission("quotation:create")) {
        _this.$router.push(
          _this.getDefaultRoute("quotation.create", {
            query: {
              duplicate: _this.detail.id,
              customer: _this.lodash.toSafeInteger(
                _this.detail.customer.customer
              ),
              contact_person: _this.lodash.toSafeInteger(
                _this.detail.contact_person.contact_person
              ),
              billing: _this.lodash.toSafeInteger(_this.detail.billing.billing),
            },
          })
        );
      }
    },
    reviseQuotation() {
      const _this = this;
      if (_this.getPermission("quotation:update")) {
        _this.$router.push(
          _this.getDefaultRoute("quotation.create", {
            query: {
              revise: _this.detail.id,
              customer: _this.lodash.toSafeInteger(
                _this.detail.customer.customer
              ),
              contact_person: _this.lodash.toSafeInteger(
                _this.detail.contact_person.contact_person
              ),
              billing: _this.lodash.toSafeInteger(_this.detail.billing.billing),
            },
          })
        );
      }
    },
    editQuotation() {
      const _this = this;
      if (_this.getPermission("quotation:update")) {
        _this.$router.push(
          _this.getDefaultRoute("quotation.update", {
            params: {
              id: _this.detail.id,
            },
            query: {
              customer: _this.lodash.toSafeInteger(
                _this.detail.customer.customer
              ),
              contact_person: _this.lodash.toSafeInteger(
                _this.detail.contact_person.contact_person
              ),
              billing: _this.lodash.toSafeInteger(_this.detail.billing.billing),
            },
          })
        );
      }
    },
    patchQuotation(data, force = false) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (_this.canUpdateQuotation || force) {
          _this.$store
            .dispatch(PATCH, { url: "quotation/" + _this.detail.id, data })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject(new Error("You don't have permission to update quotation"));
        }
      });
    },
    updateField(type) {
      const _this = this;
      if (_this.canUpdateQuotation) {
        _this.onEscParticularField();
        _this.updateDetailDialog[type] = true;
        _this.updateDetail[type] = _this.detail[type];
        setTimeout(function () {
          if (_this.$refs[type]) {
            _this.$refs[type].focus();
          }
        }, 50);
      }
    },
    onEscParticularField() {
      this.updateDetailDialog = {
        description: false,
        job_title: false,
        rating: false,
        reference: false,
        sales: false,
        prepared_by: false,
        date: false,
        sent_date: false,
        open_till: false,
      };
      this.updateDetail = new Object({
        description: this.detail.description,
        job_title: this.detail.job_title,
        rating: this.detail.rating,
        reference: this.detail.reference,
        sales: this.detail.sales,
        prepared_by: this.detail.prepared_by,
        date: this.detail.date,
        sent_date: this.detail.sent_date,
        open_till: this.detail.open_till,
      });
    },
    updateParticularField() {
      const _this = this;
      _this.particuleFieldLoading = true;
      _this.createdDatePicker = false;
      _this.sentDatePicker = false;
      _this.openTillPicker = false;
      _this
        .patchQuotation(_this.updateDetail)
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.loadContent();
          _this.particuleFieldLoading = false;
          _this.onEscParticularField();
        });
    },
    loadContent() {
      const _this = this;
      _this
        .getQuotation()
        .then((response) => {
          if (_this.lodash.isEmpty(response.ticket) === false) {
            _this.jobId = _this.lodash.toSafeInteger(response.ticket.id);
          }
          if (_this.lodash.isEmpty(response.invoice) === false) {
            _this.invoiceId = _this.lodash.toSafeInteger(response.invoice.id);
          }
          _this.moreActions = response.more_actions;
          _this.detail = response;
          _this.customerId = response.customer ? response.customer.customer : 0;
          _this.customerPersonId = response.contact_person
            ? response.contact_person.contact_person
            : 0;
          _this.customer = response.customer;
          _this.property = response.property;
          _this.billing = response.billing;
          _this.contact_person = response.contact_person;
          _this.line_items = response.line_items;
          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Quotation", route: "quotation" },
            { title: "Detail" },
            { title: response.barcode },
          ]);
        })
        .catch((error) => {
          _this.logError(error);
          _this.$router.go(-1);
        })
        .finally(() => {
          _this.lineItemDialog = false;
          _this.pageLoading = false;
          _this.onEscParticularField();
        });
    },
    getQuotation() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, { url: "quotation/" + _this.quotation })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getFormattedDate(date) {
      return this.formatDate(date);
    },
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Quotation",
        route: "quotation",
      },
      {
        title: "Detail",
      },
    ]);
  },
  created() {
    const _this = this;
    _this.quotation = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.quotation || _this.quotation <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.quotationTab = _this.$route.query.tab;
    }
    _this.loadContent();
  },
  computed: {
    canUpdateQuotation() {
      if (
        this.detail &&
        (this.lodash.isEmpty(this.detail.revisions) === false ||
          this.detail.status != 1)
      ) {
        return false;
      }
      return this.getPermission("quotation:update");
    },
    quotationTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.quotationTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "other";
      },
    },
    BillingDetail() {
      let billingArr = new Array();
      if (this.billing) {
        if (this.billing.street_1) {
          billingArr.push(this.billing.street_1);
        }
        if (this.billing.unit_no) {
          billingArr.push(this.billing.unit_no);
        }
        if (this.billing.street_2) {
          billingArr.push(this.billing.street_2);
        }
        if (this.CityBillingName) {
          billingArr.push(this.CityBillingName);
        }
        if (this.StateBillingName) {
          billingArr.push(this.StateBillingName);
        }
        if (this.CountryBillingName) {
          billingArr.push(this.CountryBillingName);
        }
        if (this.billing.zip_code) {
          billingArr.push(this.billing.zip_code);
        }
      }
      return billingArr.join(", ");
    },
    CityBillingName() {
      return this.lodash.isEmpty(this.billing.city) === false
        ? this.billing.city.name
        : null;
    },
    StateBillingName() {
      return this.lodash.isEmpty(this.billing.state) === false
        ? this.billing.state.name
        : null;
    },
    CountryBillingName() {
      return this.lodash.isEmpty(this.billing.country) === false
        ? this.billing.country.name
        : null;
    },
    PropertyDetail() {
      let propertyArr = new Array();
      if (this.property) {
        if (this.property.unit_no) {
          propertyArr.push(this.property.unit_no);
        }
        if (this.property.street_1) {
          propertyArr.push(this.property.street_1);
        }
        if (this.property.street_2) {
          propertyArr.push(this.property.street_2);
        }
        if (this.property.zip_code) {
          propertyArr.push(this.property.zip_code);
        }
        if (this.CityName) {
          propertyArr.push(this.CityName);
        }
        if (this.StateName) {
          propertyArr.push(this.StateName);
        }
        if (this.CountryName) {
          propertyArr.push(this.CountryName);
        }
      }
      return propertyArr.join(", ");
    },
    CityName() {
      return this.lodash.isEmpty(this.property.city) === false
        ? this.property.city.name
        : null;
    },
    StateName() {
      return this.lodash.isEmpty(this.property.state) === false
        ? this.property.state.name
        : null;
    },
    CountryName() {
      return this.lodash.isEmpty(this.property.country) === false
        ? this.property.country.name
        : null;
    },
    validSignature() {
      return this.sales_signature ? this.sales_signature.isEmpty() : false;
    },
  },
};
